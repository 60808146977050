.nav-menu {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 112px);
}

.nav-menu-main {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    overflow-y: auto;
}

.nav-menu-bottom {
    flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
    .nav-menu {
        height: 100vh;
    }
}
