.header-brand > svg {
    height: 48px;
}

@media only screen and (max-width: 767px) {
    .header-brand > svg {
        height: 36px;
    }
}


@media only screen and (max-width: 320px) {
    .header > .container-fluid > div:first-child {
        width: 50px !important;
    }

    .header > div > .logo-container {
        width: calc(100% - 216px + 50px) !important;
    }
}
