@keyframes pulse {
    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.1;
    }
}

.app-loading > .app-loading-logo {
    animation: pulse 2s infinite;
}

.app-loading > svg {
    height: 200px;
}

@media only screen and (max-width: 767px) {
    .app-loading>svg {
        height: 120px;
    }
}
