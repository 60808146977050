.editor {
    width: 100%;

    .editor-label {
        margin-top: 1rem;
        font-weight: 500;

        &.hide {
            opacity: 0;
            visibility: 0;
        }
    }

    .input-limit {
        width: 100%;
        text-align: end;
        margin-top: 6px;
        height: 0;
        overflow: hidden;

        animation: collapse 0.2s;
        -webkit-animation: collapse 0.2s;
        -moz-animation: collapse 0.2s;
        -o-animation: collapse 0.2s;
        -ms-animation: collapse 0.2s;
        animation-fill-mode: forwards;
    }

    .tox-tinymce {
        border-width: 0px !important;
    }

    .tox-editor-header {
        display: none !important;
    }

    .editor-error-message {
        padding-left: 2px;
    }

    @keyframes collapse {
        0% {
            height: 0;
        }

        100% {
            height: 18px;
        }
    }

    @-moz-keyframes collapse {
        0% {
            height: 0;
        }

        100% {
            height: 18px;
        }
    }

    @-webkit-keyframes collapse {
        0% {
            height: 0;
        }

        100% {
            height: 18px;
        }
    }

    @-o-keyframes collapse {
        0% {
            height: 0;
        }

        100% {
            height: 18px;
        }
    }

    @-ms-keyframes collapse {
        0% {
            height: 0;
        }

        100% {
            height: 18px;
        }
    }

}
