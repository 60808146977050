.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.event-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.event-container {
    height: 50px;
    display: flex;
}

.event-tournament,
.event-team,
.event-date {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 4px;
    overflow: hidden;
}

.event-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.event-container .divider {
    height: 100%;
    width: 1px;
    margin: 0 8px 0 8px;
    background-color: #ffffff50;
}

@media only screen and (max-width: 767px) {
    .event-container {
        display: none;
    }
}
