li.app-sidebar a {
    display: inline-block !important;
    width: 100% !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    padding-left: 1rem !important;
    font-size: 10pt;
}

li.app-sidebar {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
